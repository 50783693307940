import React from 'react';
import { Link } from 'react-router-dom';
import UUID from 'node-uuid';
import persist from 'react-localstorage-hoc';
import ReactGA from 'react-ga';

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ReactPlayer from 'react-player';

import './app.css';
import KotatsuImage from '../image/kotatsu_squire.png';

import AsaVoice from '../media/asa.mp3';
import KittyVoice from '../media/kitty.mp3';
import PPCVoice from '../media/pp-corn.mp3';
import Obeya from '../media/obeya.mp3';
import We from '../media/we.mp3';

const SoundButton = ({ text, handler, soundFile }) => (
  <Button
    variant="contained"
    onClick={() => {
      handler(soundFile, text);
    }}
  >
    {text}
  </Button>
);

class Wanko extends React.Component {
  state = { players: [], moderate: true, playCount: 0 };

  componentDidMount() {
    this.setState({ players: [] });
  }

  addPlayer(url, label) {
    ReactGA.event({ category: 'Sound', action: 'Play', label: label });
    const playCount = this.state.playCount + 1;
    this.setState({ players: [...this.state.players, { id: UUID.v4(), url }], playCount });
  }

  handleRemovePlayer(key) {
    const players = this.state.players.filter(({ id, url }) => id !== key);
    this.setState({ players });
  }

  render() {
    const addPlayerHandler = this.addPlayer.bind(this);
    return (
      <div className="App">
        <div className="image-area">
          <img
            src={KotatsuImage}
            alt="inu"
            className={'inu' + (this.state.players.length > 0 ? ' rotate-anime' : '')}
          />
        </div>
        <div className="control-area">
          <p>
            <SoundButton text={'起こしてもらう'} handler={addPlayerHandler} soundFile={AsaVoice} />
            <SoundButton
              text={'ポップコーンを作る'}
              handler={addPlayerHandler}
              soundFile={KittyVoice}
            />
            <SoundButton
              text={'ポップコーンを推す'}
              handler={addPlayerHandler}
              soundFile={PPCVoice}
            />
            <SoundButton
              text={'部屋が汚いことを指摘する'}
              handler={addPlayerHandler}
              soundFile={Obeya}
            />
            <SoundButton text={'うぇ'} handler={addPlayerHandler} soundFile={We} />
          </p>
          <p>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.moderate}
                  onClick={() => {
                    this.setState({ moderate: !this.state.moderate });
                  }}
                />
              }
              label="音量控えめ"
            />
          </p>
          {this.state.playCount > 100 ? (
            <p>あなたは、{this.state.playCount}回わんこのこえを再生したよ。</p>
          ) : null}
          {this.state.playCount > 200 ? (
            <Link to={'/kitaya'}>KITAYAさんのずびずびを聞きにいく</Link>
          ) : null}
        </div>
        {this.state.players.map(({ id, url }) => (
          <ReactPlayer
            key={id}
            url={url}
            playing={true}
            volume={this.state.moderate ? 0.5 : 1}
            onEnded={this.handleRemovePlayer.bind(this, id)}
            height={'0px'}
            width={'0px'}
          />
        ))}
      </div>
    );
  }
}

const WankoWithLocalStorage = persist(Wanko);

class WankoContainer extends React.Component {
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return <WankoWithLocalStorage />;
  }
}

export default WankoContainer;

import React from 'react';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default class ReloadModal extends React.Component {
  state = { show: false };

  componentDidMount() {
    // グローバルイベントを引っ掛ける。
    window.addEventListener("newContentAvailable", () => {
      this.setState({ show: true });
    });
  }

  render() {
    if (!this.state.show) {
      return null;
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        open={this.state.show}
        onClose={this.handleClose}
        ContentProps={{ 'aria-describedby': 'message-id', }}
        message={<span id="message-id">新しいバージョンがリリースわんこなので、更新してください </span>}
        action={[
          <Button key="refresh" color="secondary" size="small" onClick={() => window.location.reload(window.location.href)}>
            REFRESH
          </Button>,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={e => { this.setState({ show: false }); }}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}
import React from 'react';
import { Link } from 'react-router-dom';
import UUID from 'node-uuid';
import ReactGA from 'react-ga';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ReactPlayer from 'react-player';

import './app.css';
import KitayaImage from '../image/kitaya.jpg';

import ZubiVoice from '../media/zubi.mp3';

class Kitaya extends React.Component {
  state = { players: [], moderate: true };

  addPlayer() {
    this.setState({ players: [...this.state.players, { id: UUID.v4(), url: ZubiVoice }] });
  }

  handleRemovePlayer(key) {
    const players = this.state.players.filter(({ id, url }) => id !== key);
    this.setState({ players });
  }

  render() {
    return (
      <div className="App">
        <div className="image-area">
          <img
            src={KitayaImage}
            alt="kitaya"
            className={'kitaya' + (this.state.players.length > 0 ? ' furueru-anime' : '')}
            onClick={this.addPlayer.bind(this)}
          />
        </div>
        <div className="control-area">
          <p>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.moderate}
                  onClick={() => {
                    this.setState({ moderate: !this.state.moderate });
                  }}
                />
              }
              label="音量控えめ"
            />
          </p>
          <Link to={'/'}>わんこのもとへかえる</Link>
        </div>
        {this.state.players.map(({ id, url }) => (
          <ReactPlayer
            key={id}
            url={url}
            playing={true}
            volume={this.state.moderate ? 0.5 : 1}
            onEnded={this.handleRemovePlayer.bind(this, id)}
            height={'0px'}
            width={'0px'}
          />
        ))}
      </div>
    );
  }
}

class KitayaContainer extends React.Component {
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return <Kitaya />;
  }
}

export default KitayaContainer;

import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Wanko from './components/wanko_app';
import Kitaya from './components/kitaya_app';
import Second from './components/second_app';

import ReloadModal from './components/reload_modal';

const App = () => (
  <BrowserRouter>
    <div>
      <ReloadModal />
      <Route exact path="/" component={Wanko} />
      <Route exact path="/kitaya" component={Kitaya} />
      <Route exact path="/second" component={Second} />
    </div>
  </BrowserRouter>
);

export default App;

import React from 'react';
import ReactGA from 'react-ga';

import Slider from '@material-ui/lab/Slider';

import './app.css';

import before_wanko from '../image/before_wanko.jpeg';
import after_wanko from '../image/after_wanko.jpeg';
import new_wanko from '../image/new_wanko.jpeg';

class Second extends React.Component {
  state = { value: 0 };

  handleChange(e, value) {
    console.log(value);
    this.setState({ value });
  }

  render() {
    return <div className="App">
        <div className="image-area">
          <img className="before-wanko" src={before_wanko} alt="v1.0" />
          {this.state.value < 90 ? <img className="after-wanko" src={after_wanko} style={{ opacity: this.state.value * 0.01 }} alt="v2.0" /> : <img className="new-wanko" src={new_wanko} alt="new" />}
        </div>
        <div className="slider-area">
          <Slider value={typeof this.state.value === 'number' ? this.state.value : 0} onChange={this.handleChange.bind(this)} />
        </div>
      </div>;
  }
}

class SecondContainer extends React.Component {
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return <Second />;
  }
}

export default SecondContainer;

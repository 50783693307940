import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactGA.initialize('UA-78754917-2', { debug: process.env.NODE_ENV === 'development' });

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register({
  onUpdate: () => {
    const event = new Event('newContentAvailable');
    window.dispatchEvent(event);
  },
});
